<template>
  <div>
    <div style="padding-left: 5px;padding-top: 5px; padding-bottom: 5px;">
    <v-btn @click="toggleCollapse">{{ collapsed ? 'Show Create Dialog' : 'Hide Dialog' }}</v-btn>
    </div>
    <my-collapsible :collapsed="collapsed">
      <v-card style="border: 1px solid black; box-shadow: 0 2px 1px rgba(0,0,0,0.2);">
        <v-card-title>Dynamic SQL Query Tool</v-card-title>
        <v-card-text>
          
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field 
                            label="Group Name"
                            v-model="newGroupName"
                            >
                            </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-select v-model="columnName" :items="fieldNames" label="Field name" :rules="[requiredRule]"></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select v-model="operator" :items="operators" label="Operator"></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="value" label="Value" v-on="on" :rules="[requiredRule]"></v-text-field>
                    </template>
                    <span>Tooltip text</span>
                  </v-tooltip>  
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn class="font-weight-bold text-xs btn-default bg-gradient-default" @click="addCondition">Add Condition</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-divider></v-divider>
          <!-- <v-list>
            <v-subheader>Conditions</v-subheader>
            <v-list-item v-for="(condition, index) in conditions" :key="index">
              <v-list-item-content>
                <v-list-item-title>{{ condition.column }} {{ condition.operator }} {{ condition.value }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon color="error" @click="removeCondition(index)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list> -->
          <v-list>
            <v-subheader>
              <v-row align="center">
                <v-col cols="4"><v-list-item-subtitle>Column</v-list-item-subtitle></v-col>
                <v-col cols="4"><v-list-item-subtitle>Operator</v-list-item-subtitle></v-col>
                <v-col cols="4"><v-list-item-subtitle>Value</v-list-item-subtitle></v-col>
                <v-col cols="1"></v-col>
              </v-row>
            </v-subheader>
            <v-list-item v-for="(condition, index) in conditions" :key="index">
              <v-row align="center" class="condition-row">
                <v-col cols="4">
                  <v-list-item-title>{{ condition.column }}</v-list-item-title>
                </v-col>
                <v-col cols="3">
                  <v-list-item-title>{{ condition.operator }}</v-list-item-title>
                </v-col>
                <v-col cols="4">
                  <v-list-item-title>{{ condition.value }}</v-list-item-title>
                </v-col>
                <v-col cols="1">
                  <v-btn icon color="error" @click="removeCondition(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-btn class="font-weight-bold text-xs btn-default bg-gradient-default" @click="cancel">Cancel</v-btn>&nbsp;
                <v-btn class="font-weight-bold text-xs btn-default bg-gradient-default" @click="preview">Preview</v-btn>&nbsp;
                <v-btn class="font-weight-bold text-xs btn-default bg-gradient-default" @click="create">Save</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="my-data-table-container" >
                <v-data-table :headers="headers" :items="previewData" v-if="previewData.length > 0"></v-data-table>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </my-collapsible>
    <v-container>
      <v-row>
        <v-col cols="12">
              <Grid
                ref="gridg"
                :data-items="result"
                :columns="columns">
                <template v-slot:delTemplate="{ props }">
                  <deletecell
                    :data-item="props.dataItem"
                    @remove="deleteSiteGroup"
                  />
                </template>
                <template v-slot:editTemplate="{ props }">
                  <editcell 
                    :data-item="props.dataItem"
                    @edit="editSiteGroup"
                  />
                </template>
                <grid-norecords> There is no data available custom </grid-norecords>
              </Grid>
        </v-col>
      </v-row>
    </v-container>
  </div>


</template>

<script>
import Service from "@/services/Service.js";
import MyCollapsible from './MyCollapsible.vue';
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { process } from '@progress/kendo-data-query';
import { orderBy } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-data-query";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import CommandCell from "./CommandCell";
import DeleteCell from "./DeleteCell.vue";
import EditCell from "./EditCell.vue";

import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
Vue.use(VueToast);

export default {
  components: { 
    MyCollapsible,
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    "grid-norecords": GridNoRecords,
    custom: CommandCell,
    deletecell: DeleteCell,
    editcell: EditCell,
  },
  props: {
    tick: String,
  },
  data() {
    return {
      collapsed: true,
      edit_id: null,
      filter: null,
      pageable: { pageSizes: [10, 20, 50, 100] },
      gridData: [],
      skip: 0,
      take: 20,
      random: 1,
      pageSize: 20,
      columnName: '',
      operator: '=',
      value: '',
      newGroupName: '',
      operators: ['=', '!=', '>', '<', '>=', '<=', 'LIKE'],
      fieldNames: ['customer_name', 'account_name', 'name', 'email', 'phone', 'monitoring_url', 'install_year', 'inverter_manufacturer', 'address', 'city', 'state', 'zip', 'shade_winter', 'shade_summer', 'shade_fallspring', 'monitoring_system', 'inserted_at', 'updated_at', 'check_frequency', 'solar_module_wattage', 'contact', 'is_archived', 'is_paused', 'annual_production_estimate', 'secondary_monitoring_system', 'internal_id', 'component_2', 'annual_fee', 'annual_visit', 'exp_date', 'utitlity_rate', 'performance_guarantee'],
      conditions: [],
      requiredRule: [
        v => !!v || 'This field is required'
      ],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Monitoring System', value: 'monitoring_system' },
        { text: 'Check Frequency', value: 'check_frequency' },
      ],
      
      previewData: [],
      gridData: []
    };
  },
  created: function () {
    this.getData();
  },
  computed: {
    
    result: {
      get: function () {
        return filterBy(this.gridData, this.filter).slice(
          this.skip,
          this.take + this.skip
        );
      },
    },
    //fake data
    // items() {
    //   return this.selectedSites
    // },
    itemsSelected() {
      return this.gridData.filter((item) => item.selected)
    },
    length() {
      return 7000;
    },
    inverterDetail() {
      // console.log(item)
      let invDetails = this.expandedInverterDetails;
      return invDetails;
    },
    hasItemsInEdit() {
      return this.gridData.filter((p) => p.inEdit).length > 0;
    },
    total() {
      return this.gridData ? filterBy(this.gridData, this.filter).length : 0;
    },
    user: function () {
      return this.$store.getters.currentUser;
    },
    areAllSelected () {
      const val = this.gridData.findIndex(item => item.selected === false) === -1;
      return val;
    },
    columns: function () {
      return [
        { field: "id", editable: false, title: "ID", width: "150px" },
        { field: "name", width: "275px"},
        { title: "Edit", cell: 'editTemplate', filterable: false, width: "350px" },
        { title: "Remove", cell: 'delTemplate', filterable: false, width: "350px" },
      ];
    },
  },
  watch: {
    tick() {
      console.log('refresh ');
      this.getData();
    }
  },
  methods: {
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
    addCondition() {
      this.conditions.push({
        column: this.columnName,
        operator: this.operator,
        value: this.value
      });
      this.columnName = '';
      this.operator = '=';
      this.value = '';
    },
    removeCondition(index) {
      this.conditions.splice(index, 1);
    },
    getData: function () {
      NProgress.start();
      // TODO: make sure and get only groups dynamic = false
      Service.getDynRptSiteGroups()
        .then((response) => {
          this.gridData = response.data
          // add expanded property for detail view
          // this.gridData = response.data.map(item => {
          //   return {...item, expanded: false};
          // });
          NProgress.done();
        })
        .catch((error) => {
          NProgress.done();
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
        })
    },
    deleteSiteGroup(e) {
      console.log(`delete site group ${e.dataItem.id}`);
      Service.deleteRptSiteGroup(e.dataItem.id)
        .then((response) => {
          Vue.$toast.warning(`Site Group removed`, {
            position: "top-right",
            duration: 4000,
          });
          this.getData();
        })
        .catch((error) => {
          Vue.$toast.error(`Error removing group`, { position: "top-right" });
          console.log(error);
        });
    },
    editSiteGroup(e) {
      console.log(`edit site group ${e.dataItem.id}`);
      let p1 = e.dataItem.name;
      let p2 = Object.assign({}, e.dataItem);
      // this.conditions = e.dataItem.rpt_dynamic_sites.map()
      const c = e.dataItem.rpt_dynamic_sites.map((s) => {
        return s.filter;
      });
      // set edit_id
      this.edit_id = e.dataItem.id;
      // set name
      this.newGroupName = e.dataItem.name;
      // set conditions
      this.conditions = c;
      // show dialog
      this.collapsed = false;
      // query site group and show top section, and populate values
      console.log(c);
    },
    preview() {
      var params = {
        name: this.newGroupName,
        conditions: this.conditions
      };
      Service.dynSiteGroupQuery(params)
        .then((response) => {
          console.log("success")
          this.previewData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancel() {
      this.edit_id = null;
      this.conditions = [];
      this.newGroupName = "";
      this.collapsed = true;
    },
    create() {
      var params = {
        id: this.edit_id,
        name: this.newGroupName,
        conditions: this.conditions
      };
      Service.createDynSiteGroup(params)
        .then((response) => {
          console.log("success")
          Vue.$toast.success(`Site Group saved`, {
            position: "top-right",
            duration: 4000,
          });
          this.getData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};
</script>
<style scoped>
    .condition-row {
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 8px;
  }
  .my-data-table-container {
  border: 1px solid #f70909; /* light red border */
}

</style>